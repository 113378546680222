<template>
    <a @click.prevent="click" class="wow button" :class="{'loading': isLoading}" ref="button">
        <span class="button__label"><slot></slot></span>
        <svg class="wow spinner" width="24px" height="24px" viewBox="0 0 52 52">
            <circle class="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="6px" />
        </svg>
    </a>
</template>

<script>
    export default {
        props: [
            'isLoading',
        ],
        methods: {
            click: function(e){
                this.$emit('click', e);
            },
        },
    }
</script>
