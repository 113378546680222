/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');



import { flare } from "@flareapp/flare-client";
import { flareVue } from "@flareapp/flare-vue";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
// if (process.env.NODE_ENV === 'production') {
flare.light();
// }

Vue.use(flareVue);



import VueKonva from 'vue-konva';
Vue.use(VueKonva);

import VModal from 'vue-js-modal'
Vue.use(VModal)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import { required, email } from 'vuelidate/lib/validators';
import cpf from './validators/cpf'

window.required = required;
window.email = email;
window.cpf = cpf;

import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.mixin({
    methods: {
        capitalize(string) {
            return string[0].toUpperCase() + string.substring(1);
        }
    }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


// COMPONENTS
Vue.component('main-menu', require('./components/MainMenu').default);
Vue.component('mobile-menu', require('./components/MobileMenu').default);
Vue.component('context-menu', require('./components/ContextMenu').default);
Vue.component('invitation-text', require('./components/InvitationText').default);
Vue.component('cart', require('./components/Cart').default);
Vue.component('wow-select', require('./components/WowSelect').default);
Vue.component('loader', require('./components/Loader').default);

Vue.component('wow-select-option', require('./components/WowSelectOption').default);
Vue.component('wow-button', require('./components/WowButton').default);
Vue.component('date-picker', require('./components/DatePicker').default);
Vue.component('invitation', require('./templates/Invitation').default);
Vue.component('wow-collapse', require('./admin/components/WowCollapse').default);


Vue.component('invitation-text-konva', require('./components/InvitationTextKonva.vue').default);
Vue.component('invitation-konva', require('./components/InvitationKonva.vue').default);

import MediaQueryMixin from "./mixins/MediaQueryMixin";
Vue.mixin(MediaQueryMixin);

import UnexpectedErrorMixin from "./mixins/UnexpectedErrorMixin";
Vue.mixin(UnexpectedErrorMixin);

import ForceDownloadFileMixin from "./mixins/ForceDownloadFileMixin";
window.ForceDownloadFileMixin = ForceDownloadFileMixin;

// MODALS
Vue.component('base-modal', require('./modals/ModalBase').default);
Vue.component('unexpected-error', require('./modals/UnexpectedError').default);
Vue.component('register-modal', require('./modals/Register').default);
Vue.component('login-modal', require('./modals/Login').default);
Vue.component('download-invitation', require('./modals/DownloadInvitation').default);
Vue.component('free-download', require('./modals/FreeDownload').default);
Vue.component('account-success-updated', require('./modals/AccountSuccessUpdated').default);
Vue.component('terminate-account', require('./modals/TerminateAccount').default);
Vue.component('remove-invitation', require('./modals/RemoveInvitation').default);
Vue.component('help-message', require('./modals/HelpMessage').default);
Vue.component('help-message-confirmation', require('./modals/HelpMessageConfirmation').default);
Vue.component('pix-modal', require('./modals/Pix').default);


// WIDGETS
Vue.component('date-hour-01', require('./components/Widgets/DateHour01').default);
Vue.component('date-hour-02', require('./components/Widgets/DateHour02').default);
Vue.component('date-hour-03', require('./components/Widgets/DateHour03').default);
Vue.component('date-hour-04', require('./components/Widgets/DateHour04').default);
Vue.component('date-hour-05', require('./components/Widgets/DateHour05').default);
Vue.component('date-hour-06', require('./components/Widgets/DateHour06').default);

Vue.mixin({
    methods: {
        showLogin() {
            this.$modal.hide('register-modal');
            this.$modal.show('login-modal');
        },
        showRegister() {
            this.$modal.hide('login-modal');
            this.$modal.show('register-modal');
        },
    }
})

// footer themes mixin
Vue.mixin({
    data() {
        return {
            isFooterThemesVisible: false
        }
    },
    methods: {
        showLogin() {
            this.$modal.hide('register-modal');
            this.$modal.show('login-modal');
        },
        toggleFooterThemes() {
            this.isFooterThemesVisible = !this.isFooterThemesVisible;
        }
    }
})

// uuid mixin
Vue.mixin({
    methods: {
         uuid() {
                let chars = 'abcdef'.split('');

                let uuid = [], rnd = Math.random, r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4'; // version 4

                for (let i = 0; i < 36; i++)
                {
                    if (!uuid[i])
                    {
                        r = 0 | rnd()*16;

                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
                    }
                }

                return uuid.join('');
            }
    }
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });


