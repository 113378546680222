<template>
    <modal :name="modalName" :height="height" :width="width" @before-open="beforeOpen" @opened="opened">
        <base-modal :name="modalName">

            <div class="pix">

                <div class="pix__title">Pagar com Pix</div>

                <div class="pix__invitation-preview-container mobile-only">
                    <svg class="pix__confetti" xmlns="http://www.w3.org/2000/svg" width="223.51" height="114" viewBox="0 0 223.51 114">
                        <path id="Caminho_168" data-name="Caminho 168" d="M325.784,385.325l-4.532,4.532a1.734,1.734,0,1,0,2.452,2.452l4.532-4.532a1.734,1.734,0,0,0-2.452-2.452Z" transform="translate(-116.907 -367.817)" fill="#0097ff"/>
                        <g id="Grupo_274" data-name="Grupo 274" transform="translate(202.273 96)">
                            <path id="Caminho_66" data-name="Caminho 66" d="M37.755,339.5a2.5,2.5,0,1,0,0-5A2.5,2.5,0,0,0,37.755,339.5Z" transform="translate(-35.255 -334.5)" fill="#fcdd63"/>
                        </g>
                        <g id="Grupo_275" data-name="Grupo 275" transform="translate(18.033 20)">
                            <path id="Caminho_66-2" data-name="Caminho 66" d="M37.755,339.5a2.5,2.5,0,1,0,0-5A2.5,2.5,0,0,0,37.755,339.5Z" transform="translate(-35.255 -334.5)" fill="#fcdd63"/>
                        </g>
                        <g id="Grupo_276" data-name="Grupo 276" transform="translate(211.26)">
                            <path id="Caminho_66-3" data-name="Caminho 66" d="M37.755,339.5a2.5,2.5,0,1,0,0-5A2.5,2.5,0,0,0,37.755,339.5Z" transform="translate(-35.255 -334.5)" fill="#fcdd63"/>
                        </g>
                        <g id="Grupo_277" data-name="Grupo 277" transform="translate(18.033 104)">
                            <path id="Caminho_66-4" data-name="Caminho 66" d="M37.755,339.5a2.5,2.5,0,1,0,0-5A2.5,2.5,0,0,0,37.755,339.5Z" transform="translate(-35.255 -334.5)" fill="#fcdd63"/>
                        </g>
                        <path id="Caminho_169" data-name="Caminho 169" d="M5.04.508.508,5.04A1.734,1.734,0,1,0,2.96,7.492L7.492,2.96A1.734,1.734,0,0,0,5.04.508Z" transform="translate(15.266 8) rotate(-90)" fill="#f3705a"/>
                        <path id="Caminho_170" data-name="Caminho 170" d="M325.784,385.325l-4.532,4.532a1.734,1.734,0,1,0,2.452,2.452l4.532-4.532a1.734,1.734,0,0,0-2.452-2.452Z" transform="translate(-298.295 -332.817)" fill="#4ad295"/>
                        <path id="Caminho_171" data-name="Caminho 171" d="M5.04.508.508,5.04A1.734,1.734,0,1,0,2.96,7.492L7.492,2.96A1.734,1.734,0,0,0,5.04.508Z" transform="translate(194.858 60) rotate(-90)" fill="#f3705a"/>
                        <path id="Caminho_172" data-name="Caminho 172" d="M5.04.508.508,5.04A1.734,1.734,0,1,0,2.96,7.492L7.492,2.96A1.734,1.734,0,0,0,5.04.508Z" transform="translate(0 104) rotate(-90)" fill="#0097ff"/>
                        <path id="Caminho_173" data-name="Caminho 173" d="M325.784,385.325l-4.532,4.532a1.734,1.734,0,1,0,2.452,2.452l4.532-4.532a1.734,1.734,0,0,0-2.452-2.452Z" transform="translate(-105.234 -278.817)" fill="#4ad295"/>
                    </svg>
                    <img width="120" class="pix__invitation-preview" ref="invitation-preview-modal" alt="">
                </div>

                <div class="pix__qrcode-container desktop-only">

                    <div class="pix__qrcode">
                        <loader v-if="isLoading"></loader>
                        <img width="240" height="240" v-else :src="pix.qr_code_base64" alt="">
                    </div>

                </div>

                <div class="pix__copy-instruction desktop-only">
                    Se preferir, copie o código abaixo e utilize o
                    Pix Copia e Cola no aplicativo que você vai
                    fazer o pagamento
                </div>
                <div class="pix__copy-instruction mobile-only">
                    Copie o código abaixo e utilize o
                    Pix Copia e Cola no aplicativo que você vai
                    fazer o pagamento
                </div>

                <div class="pix__code-container">
                    <div class="pix__code-copied" v-show="copied">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Retângulo_1456" data-name="Retângulo 1456" width="20" height="20" transform="translate(880 834)" fill="#fff"/>
                                </clipPath>
                            </defs>
                            <g id="Grupo_de_máscara_224" data-name="Grupo de máscara 224" transform="translate(-880 -834)" clip-path="url(#clip-path)">
                                <path id="check_FILL1_wght400_GRAD0_opsz40" d="M11.644,22,4.731,15.084,6.206,13.61l5.438,5.469L23.287,7.434l1.444,1.475Z" transform="translate(875.269 829.284)" fill="#fff"/>
                            </g>
                        </svg>
                        Código copiado
                    </div>
                    <input type="text" class="pix__code" ref="code" :value="pix.qr_code">
                </div>

                <a href="#" @click.prevent="copyCode()" class="pix__copy-button wow button large fluid">
                    <span>Copiar código</span>
                </a>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'Pix',
        data() {
            return {
                modalName: 'pix-modal',
                copied: false,
                invitation: {},
                pix: {},
                isLoading: false
            }
        },
        computed: {
            width() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '398px'
            },
            height() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return 'auto'
            }
        },
        methods: {
            beforeOpen({params}) {
                this.invitation = params.invitation;
                this.$nextTick(() => {
                    this.createPix();
                })
            },
            opened() {
                this.getInvitationPreview();
            },
            getInvitationPreview() {
                let blob =  window.localStorage.getItem(this.invitation.uuid);
                this.$refs['invitation-preview-modal'].src = blob;
            },
            createPix() {
                if (this.pix.qr_code) {
                    return;
                }

                this.isLoading = true;

                axios.get(
                    route('invoice.generate', this.invitation)
                ).then(({data}) => {
                    this.pix = data;
                }).catch(() => {
                    alert('Houve um erro ai iniciar o pagamento');
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            copyCode() {
                let input = this.$refs.code;
                input.select();

                try {
                    let successful = document.execCommand('copy');
                    if (successful) {
                        this.copied = true;
                        setTimeout(() => {
                            this.copied = false;
                        },2000)
                    }
                } catch (err) {
                    console.log('Oops, unable to copy');
                }
                window.getSelection().removeAllRanges();
            },
        }
    }
</script>
