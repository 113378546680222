<template>
    <modal :name="modalName" :height="height" :width="width" @before-open="beforeOpen">
        <base-modal :name="modalName">

            <div class="free-download">

                <div class="icon-container">

                    <div class="icon-container__back-button mobile-only" @click="close()">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Retângulo_375" data-name="Retângulo 375" width="24" height="24" transform="translate(44.955 129.955)" fill="#99a3ba" stroke="#707070" stroke-width="1"/>
                                </clipPath>
                            </defs>
                            <g id="Grupo_de_máscara_8" data-name="Grupo de máscara 8" transform="translate(-44.955 -129.955)" clip-path="url(#clip-path)">
                                <path id="de-volta" d="M7.8,5A.848.848,0,1,1,9,6.2L2.894,12.305H23.053a.849.849,0,0,1,.856.843.859.859,0,0,1-.856.856H2.894L9,20.1a.865.865,0,0,1,0,1.2.844.844,0,0,1-1.2,0L.243,13.751a.85.85,0,0,1,0-1.193Z" transform="translate(45.001 128.799)" fill="#99a3ba"/>
                            </g>
                        </svg>
                    </div>

                    <div class="icon-container__image">
                        <span class="material-icons">priority_high</span>
                    </div>
                </div>

                <div class="free-download__title">Download grátis</div>

                <p class="free-download__description">
                    Na opção de download gratuito, o convite é disponibilizado em baixa resolução e sem as informações personalizadas.
                    Você poderá personalizar por conta própria e enviar aos convidados pelo WhatsApp.
                </p>

                <div class="free-download__buttons">
                    <wow-button @click="downloadImage(freeDownloadUrl)" class="secondary fluid" :is-loading="isLoading">Baixar versão grátis</wow-button>
                    <a href="#" class="wow button large fluid">
                        <span class="material-icons">shopping_cart</span>
                        <span>Comprar por apenas R$ 12,90</span>
                    </a>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>

    import ForceDownloadFileMixin from "../mixins/ForceDownloadFileMixin";

    export default {
        name: 'free-download',
        mixins: [ForceDownloadFileMixin],
        data() {
            return {
                modalName: 'free-download',
                template: {},
                isLoading: false
            }
        },
        computed: {
            width() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '512'
            },
            height() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '456'
            },
            freeDownloadUrl() {
                return this.template.download_image_path;
            }
        },
        methods: {
            beforeOpen({params}) {
                this.template = params.template;
            },
            close() {
                this.$modal.hide(this.modalName)
            },
            downloadImage(url) {
                this.isLoading = true;
                this.forceDownloadFile(url, 'festow.jpg').then(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>
