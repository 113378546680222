<template>
    <modal :name="modalName" height="auto" :width="width">
        <base-modal :name="modalName">

            <div class="delete-modal terminate-account-modal">

                <div class="delete-modal__top"></div>
                <span class="delete-modal__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21.999" viewBox="0 0 22 21.999">
                      <path id="Caminho_96" data-name="Caminho 96" d="M45.608,42.307a2.334,2.334,0,1,1-3.3,3.3l-7.015-7.015-7.015,7.015a2.334,2.334,0,1,1-3.3-3.3l7.015-7.015-7.015-7.015a2.334,2.334,0,1,1,3.3-3.3l7.015,7.015,7.015-7.015a2.334,2.334,0,0,1,3.3,3.3l-7.015,7.015Zm0,0" transform="translate(-24.292 -24.292)" fill="#fff"/>
                    </svg>
                </span>
                <p class="delete-modal__question">Confirmar exclusão da conta</p>
                <p class="delete-modal__description">
                    Para excluir a sua conta no Festow, clique no botão <br class="desktop-only" />
                    "Excluir conta"
                </p>

                <div class="wow form">
                    <div class="row columns row-buttons">
                        <div class="column">
                            <a :href="route('orders')" class="wow button secondary fixed-size">Voltar ao painel</a>
                        </div>
                        <div class="column">
                            <wow-button @click="confirm()" class="danger fixed-size" :is-loading="isLoading">Excluir conta</wow-button>
                        </div>
                    </div>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'terminate-account',
        data() {
            return {
                modalName: 'terminate-account',
                route,
                isLoading: false
            }
        },
        computed: {
            width() {
                let screenWidth = screen.width

                if (this.isMobileSize) {
                    return screenWidth - 32;
                }
                return '512px'
            },
        },
        methods: {
            confirm() {

                this.isLoading = true;

                axios.post(route('terminate-account.terminate'))
                .then(() => {
                    window.location = route('logout');
                }).finally(() => {
                    this.isLoading = false;
                });

            }
        }
    }
</script>
