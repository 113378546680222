<template>
    <modal :name="modalName" :height="height" :width="width">
        <base-modal :name="modalName">

            <div class="register">

                <div class="register__body">

                    <div class="register__title">Login</div>

                    <a :href="oAuthUrl" class="wow button secondary fluid large gmail-button">
                        <span>
                            <img src="/imgs/gmail-icon.svg" alt="">
                        </span>
                        <span>Entrar com Gmail</span>
                    </a>

                    <div class="or"></div>

                    <div class="wow warning fluid" v-if="error">
                        <span class="warning__icon material-icons">cancel</span>
                        <div class="warning__body">E-email ou senha incorretos</div>
                    </div>

                    <div class="wow form register__form">
                        <div class="row">
                            <label class="wow label">E-mail</label>
                            <input class="wow input fluid large" v-model="email" type="text" />
                        </div>
                        <div class="row">
                            <label class="wow label">Senha</label>
                            <input class="wow input fluid large" v-model="password" type="password" />
                        </div>
                    </div>

                    <wow-button class="large fluid submit" @click="send" :is-loading="isLoading">Entrar</wow-button>

                    <div class="register__legal-info">
                        <a href="#" class="legal-link underline">Esqueceu a senha?</a>
                    </div>

                </div>

                <div class="register__bottom">
                    <span>Ainda não possui uma conta?</span>
                    <a href="#" class="simple-link" @click.prevent="showRegister">Clique aqui para se cadastrar</a>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                modalName: 'login-modal',
                isLoading: false,
                email: null,
                password: null,
                error: false
            }
        },
        computed: {
            oAuthUrl() {
                return route('oauth.google')
            },
            width() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '398px'
            },
            height() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return 'auto'
            }
        },
        methods: {
            send() {

                if (!this.email || !this.password) {
                    this.error = true;
                    return false;
                }

                this.isLoading = true;

                axios.post(
                    route('auth'),
                    {
                        email: this.email,
                        password: this.password
                    }
                ).then(({data}) => {
                    if (data.status) {
                        window.location = route('auth-router')
                    }
                }).catch(({response}) => {

                    if (typeof response.data.errors !== 'undefined' && response.data.errors.email[0] === 'auth.failed') {
                        this.error = true;
                        return false;
                    }

                    this.showUnexpectedErrorModal();
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>
