<template>
    <div></div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Number, Object],
                // required: true
            },
            selected: {
                type: Boolean,
                default: false
            },
            image: {
                type: String,
            },
            icon: {
                type: String,
            },
            component: {
                type: Object
            },
            actionlabel: {
                type: String
            },
            action: {
                type: Function
            },
            classes: {
                type: String,
                default: ''
            },
            customStyle: {
                type: Object
            }
        },
        mounted(){
            if(this.$slots.default[0].text){
                // this.label = this.$slots.default[0].text
            }
        }
    }
</script>

<style scoped>

</style>
