<template>
    <modal :name="modalName" height="auto" :width="width" @before-open="beforeOpen">
        <base-modal :name="modalName">

            <div class="modal alert delete-modal terminate-account-modal">

                <div class="delete-modal__top"></div>
                <span class="delete-modal__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="34" viewBox="0 0 6 34">
                      <g id="Grupo_122" data-name="Grupo 122" transform="translate(-959 -325)">
                        <rect id="Retângulo_432" data-name="Retângulo 432" width="6" height="24" rx="3" transform="translate(959 325)" fill="#fff"/>
                        <circle id="Elipse_63" data-name="Elipse 63" cx="3" cy="3" r="3" transform="translate(959 353)" fill="#fff"/>
                      </g>
                    </svg>
                </span>
                <p class="delete-modal__question">Excluir convite</p>
                <p class="delete-modal__description">
                    Ao confirmar a exclusão do convite, ele será deletado <br class="desktop-only" />
                    definitivamente do sistema, e você não poderá mais visualizar <br class="desktop-only" />
                    ou editar as informações.
                </p>

                <div class="wow form">
                    <div class="row columns row-buttons">
                        <div class="column">
                            <a :href="route('orders')" class="wow button secondary fixed-size">Cancelar</a>
                        </div>
                        <div class="column">
                            <wow-button @click="confirm()" class="fixed-size" :is-loading="isLoading">Excluir</wow-button>
                        </div>
                    </div>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'remove-invitation',
        data() {
            return {
                modalName: 'remove-invitation',
                route,
                invitation: {},
                isLoading: false
            }
        },
        computed: {
            width() {
                let screenWidth = screen.width

                if (this.isMobileSize) {
                    return screenWidth - 32;
                }
                return '512px'
            },
        },
        methods: {
            beforeOpen({params}) {
                this.invitation = params.invitation
            },
            confirm() {

                this.isLoading = true;

                axios.delete(
                    route('order.delete', this.invitation)
                ).then(() => {
                    this.$emit('done', this.invitation)
                    this.$modal.hide(this.modalName)
                }).finally(() => {
                    this.isLoading = false;
                });

            }
        }
    }
</script>
