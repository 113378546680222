<template>
    <modal :name="modalName" height="auto" :width="width">
        <base-modal :name="modalName">

            <div class="unexpected-error">
                <div class="unexpected-error__title">Ops</div>

                <p>Houve um erro inesperado. Por favor tente novamente.</p>

                <br />
                <br />

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'unexpected-error',
        data() {
            return {
                modalName: 'unexpected-error',
            }
        },
        computed: {
            width() {
                let screenWidth = screen.width

                if (this.isMobileSize) {
                    return screenWidth - 32;
                }
                return '382px'
            },
        },
    }
</script>
