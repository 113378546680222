<template>
    <div class="base-modal">
        <a @click="close()" class="material-icons base-modal__close">close</a>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: 'BaseModal',
        props: [ 'name' ],
        methods: {
            close() {
                this.$modal.hide(this.name)
            }
        }
    }
</script>
<style lang="scss">
    .base-modal {
        height: 100%;
    }

    .base-modal__close {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #99A3BA;
        border:  1px solid #99A3BA;
        font-size: 16px;
        cursor: pointer;
        z-index: 10;
    }

</style>
