<template>
    <nav class="main-menu desktop-only">

        <div class="main-menu__container">
            <div class="wrap main-menu__wrap">
                <div class="main-menu__item">
                    <a href="#" class="main-menu__category">Aniversário infantil</a>
                </div>
                <div class="main-menu__item">
                    <a href="#" class="main-menu__category">Aniversário adulto</a>
                </div>

                <div class="main-menu__item main-menu__item--with-submenu" >
                    <a href="#" class="main-menu__category">Chás</a>
                    <div class="main-menu__sub-menu__overlay" @click.self="closeSubMenu">
                        <div class="main-menu__sub-menu sub-menu">
                            <div class="wrap sub-menu__wrap">
                                <a href="#" class="sub-menu__item">Chá de bebê</a>
                                <a href="#" class="sub-menu__item">Chá bar</a>
                                <a href="#" class="sub-menu__item">Chá de panela</a>
                                <a href="#" class="sub-menu__item">Chá de lingerie</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-menu__item main-menu__item--with-submenu">
                    <a href="#" class="main-menu__category">Batizados</a>
                    <div class="main-menu__sub-menu__overlay" @click.self="closeSubMenu">
                        <div class="main-menu__sub-menu sub-menu">
                            <div class="wrap sub-menu__wrap">
                                <a href="#" class="sub-menu__item">Chá de bebê</a>
                                <a href="#" class="sub-menu__item">Chá bar</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="main-menu__item">
                    <a href="#" class="main-menu__category">Datas comemorativas</a>
                </div>
            </div>
        </div>

    </nav>
</template>

<script>
export default {
    data() {
        return {
            fontSize: 1,
            minSize: 3,
            lineHeight: 4,
            isVisible: false,
            categories: [],
            selectedCategory: null,
        };
    },
    mounted() {
        this.getCategoriesFromDB();
    },
    computed: {
        isMainMenu() {
            return !this.selectedCategory;
        },
        isSubMenu() {
            return !!this.selectedCategory;
        },
        themes() {
            if (!this.selectedCategory) {
                return [];
            }
            return this.selectedCategory.themes;
        },
    },
    methods: {
        closeSubMenu(event) {
        // .main-menu .main-menu__item--with-submenu:hover .main-menu__sub-menu__overlay
            let overlays = document.getElementsByClassName('main-menu__sub-menu__overlay')
            event.target.style.display = 'none'
            setTimeout(() => {
                event.target.style.display = null
            },1000);
            // overlays.forEach((overlay) => {
            //     console.log(overlay)
            // });
        },
        showMenu() {
            this.isVisible = true;
        },
        hideMenu() {
            this.isVisible = false;
        },
        showMainMenu() {
            this.selectedCategory = null;
        },
        showSubMenu(category) {
            this.selectedCategory = category;
        },
        getCategoriesFromDB() {
            axios.get(route("home.categories")).then(({ data }) => {
                this.categories = data;
            });
        },
    },
};
</script>

<style></style>
