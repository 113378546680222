<template>
    <modal :name="modalName" height="auto" width="512">
        <base-modal :name="modalName">

            <div class="admin-modal delete-modal modal success">

                <div class="delete-modal__top">.</div>
                <span class="delete-modal__icon material-icons">check_circle</span>
                <p class="delete-modal__question">Mensagem enviada ;)</p>

                <p class="modal__info">
                    Responderemos em seu e-mail o mais breve possível. <br class="desktop-only" />
                    Nosso horário de atendimento é de segunda a sexta-feira, das 09h às 17h.
                </p>

                <div class="wow form">
                    <div class="row columns row-buttons">
                        <div class="column">
                            <a href="." class="wow button primary fixed-size">Voltar ao site</a>
                        </div>
                    </div>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
export default {
    name: 'help-message-confirmation',
    data() {
        return {
            modalName: 'help-message-confirmation',
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm', this.invoice)
            this.$modal.hide(this.modalName);
        },
    }
}
</script>

<style scoped lang="scss">


</style>
