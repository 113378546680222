<template>
    <v-group ref="group" :config="{
            x: x,
            y: this.config.y,
            name: 'widget',
            draggable: editMode,
        }" @transformend="handleTransformEnd" @dragend="handleDragEnd">
        <v-text ref="element_0" :config="elementOneConfig"/>
        <v-text ref="element_1" :config="elementTwoConfig"/>
    </v-group>
</template>

<script>
import KonvaAlignmentsMixin from "../../admin/mixins/KonvaAlignmentsMixin";
import WidgetMixing from "../../mixins/WidgetMixin"

export default {
    name: "date-hour-03",
    mixins: [KonvaAlignmentsMixin, WidgetMixing],
    props: {
        config: Object,
        positions: Boolean,
        date: String,
        hour: String,
        editMode: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            elements: 2
        }
    },
    mounted() {
        setTimeout(() => {
            this.calculateElementsPositions();
        },200)
    },
    computed: {
        datejs() {
            return dayjs(`${this.date} ${this.hour}`)
        },
        dayMonth() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('DD MMM');
        },
        weekday() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('dddd');
        },
        formattedHour() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('HH:mm');
        },
        textElementOne() {
            if (!this.isDateValid) {
                return ''
            }
            return `${this.dayMonth} | ${this.weekday} | ${this.formattedHour}`
        },
        textElementOTwo() {
            if (!this.isDateValid) {
                return ''
            }
            return `${this.dayMonth} | ${this.weekday} | ${this.formattedHour}`
        },
        elementOneConfig() {
            return {
                ...this.config,
                x: 0,
                y: 0,
                text: this.dayMonth,
                name: 'text',
                fontFamily: this.config.elements[0].style.font.name,
                fontSize: this.forceNumber(this.config.elements[0].style.fontSize, 30),
                fontStyle: this.config.elements[0].style.fontStyle.weight,
                lineHeight: this.forceNumber(this.config.elements[0].style.lineHeight, 1),
                letterSpacing: this.forceNumber(this.config.elements[0].style.letterSpacing, 1),
                fill: this.config.elements[0].style.color,
                align: 'center',
                height: null,
                width: null
            }
        },
        elementTwoConfig() {
            return {
                ...this.config,
                x: 0,
                y: 30,
                text: `${this.weekday} | ${this.formattedHour}`,
                name: 'text',
                fontFamily: this.config.elements[1].style.font.name,
                fontSize: this.forceNumber(this.config.elements[1].style.fontSize, 30),
                fontStyle: this.config.elements[1].style.fontStyle.weight,
                lineHeight: this.forceNumber(this.config.elements[1].style.lineHeight, 1),
                letterSpacing: this.forceNumber(this.config.elements[1].style.letterSpacing, 1),
                fill: this.config.elements[1].style.color,
                align: 'center',
                height: null,
                width: null
            }
        },
    },
    methods: {
        calculateElementsPositions() {
            let elementOne = this.$refs.element_0.getStage()
            let elementTwo = this.$refs.element_1.getStage()
            let group = this.$refs.group.getStage()

            group.width(Math.max(elementOne.textWidth, elementTwo.textWidth))

            elementTwo.y(elementOne.y() + elementOne.textHeight + 10)

            if (this.config.horizontal_align === 'left') {
                this.alignLeft(elementTwo)
                this.alignLeft(elementOne)
                return;
            }

            if (this.config.horizontal_align === 'center') {
                this.alignCenter(elementTwo)
                this.alignCenter(elementOne)
                return;
            }

            this.alignRight(elementTwo)
            this.alignRight(elementOne)
        },
    },
}
</script>
