<template>
    <div class="menu-mobile">
        <span class="sandwich-menu-icon" @click="showMenu()">
            <span class="material-icons">menu</span>
        </span>

        <div
            class="menu-mobile__overlay"
            v-if="isVisible"
            @click.self="hideMenu()"
        >
            <div class="menu-mobile__body">
                <div class="menu-mobile__top-button">
                    <div
                        class="menu-mobile__back-button"
                        @click="showMainMenu()"
                        v-if="isSubMenu"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <defs>
                                <clipPath id="clip-path">
                                    <rect
                                        id="Retângulo_375"
                                        data-name="Retângulo 375"
                                        width="24"
                                        height="24"
                                        transform="translate(44.955 129.955)"
                                        fill="#99a3ba"
                                        stroke="#707070"
                                        stroke-width="1"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="Grupo_de_máscara_8"
                                data-name="Grupo de máscara 8"
                                transform="translate(-44.955 -129.955)"
                                clip-path="url(#clip-path)"
                            >
                                <path
                                    id="de-volta"
                                    d="M7.8,5A.848.848,0,1,1,9,6.2L2.894,12.305H23.053a.849.849,0,0,1,.856.843.859.859,0,0,1-.856.856H2.894L9,20.1a.865.865,0,0,1,0,1.2.844.844,0,0,1-1.2,0L.243,13.751a.85.85,0,0,1,0-1.193Z"
                                    transform="translate(45.001 128.799)"
                                    fill="#99a3ba"
                                />
                            </g>
                        </svg>
                    </div>
                    <div
                        class="menu-mobile__close-button"
                        @click="hideMenu()"
                        v-if="isMainMenu"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <defs>
                                <clipPath id="clip-path-close">
                                    <circle
                                        id="Elipse_18"
                                        data-name="Elipse 18"
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="translate(1856 16)"
                                        fill="#99a3ba"
                                        stroke="#707070"
                                        stroke-width="1"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="Grupo_de_máscara_6"
                                data-name="Grupo de máscara 6"
                                transform="translate(-1856 -16)"
                                clip-path="url(#clip-path-close)"
                            >
                                <g id="remover" transform="translate(1856 16)">
                                    <path
                                        id="Caminho_16"
                                        data-name="Caminho 16"
                                        d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,1.5A10.5,10.5,0,1,0,22.5,12,10.512,10.512,0,0,0,12,1.5Zm0,0"
                                        fill="#99a3ba"
                                    />
                                    <path
                                        id="Caminho_17"
                                        data-name="Caminho 17"
                                        d="M8.288,16.462a.75.75,0,0,1-.53-1.281l7.425-7.425a.75.75,0,0,1,1.061,1.061L8.819,16.242a.751.751,0,0,1-.531.22Zm0,0"
                                        fill="#99a3ba"
                                    />
                                    <path
                                        id="Caminho_18"
                                        data-name="Caminho 18"
                                        d="M15.712,16.462a.744.744,0,0,1-.53-.22L7.757,8.818A.75.75,0,1,1,8.818,7.757l7.425,7.425a.749.749,0,0,1-.531,1.28Zm0,0"
                                        fill="#99a3ba"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>

                <div class="menu-mobile__stage">
                    <div
                        class="menu-mobile__main-menu-container"
                        v-if="isMainMenu"
                    >
                        <div class="menu-mobile__title">QUAL É SEU EVENTO</div>

                        <div class="menu-mobile__main-menu">
                            <div
                                class="menu-mobile__main-menu-item"
                                v-for="category in categories"
                                @click="showSubMenu(category)"
                            >
                                {{ category.name }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="menu-mobile__sub-menu-container"
                        v-if="isSubMenu"
                    >
                        <div class="menu-mobile__title">
                            {{ selectedCategory.name }}
                        </div>
                        <div class="menu-mobile__sub-menu">
                            <a
                                :href="theme.url"
                                class="menu-mobile__sub-menu-item"
                                v-for="theme in themes"
                                >{{ theme.name }}</a
                            >
                        </div>
                    </div>
                </div>

                <div class="menu-mobile__footer" v-if="isMainMenu">
                    <a :href="helpCenterUrl" class="menu-mobile__footer-item">
                        <div class="menu-mobile__footer-item-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect
                                            id="Retângulo_515"
                                            data-name="Retângulo 515"
                                            width="24"
                                            height="24"
                                            transform="translate(1432 32)"
                                            fill="#99a3ba"
                                            stroke="#707070"
                                            stroke-width="1"
                                        />
                                    </clipPath>
                                </defs>
                                <g
                                    id="Grupo_de_máscara_7"
                                    data-name="Grupo de máscara 7"
                                    transform="translate(-1432 -32)"
                                    clip-path="url(#clip-path)"
                                >
                                    <g
                                        id="pergunta"
                                        transform="translate(1432 32)"
                                    >
                                        <path
                                            id="Caminho_161"
                                            data-name="Caminho 161"
                                            d="M13,18a1,1,0,1,1-1-1A1,1,0,0,1,13,18Zm0,0"
                                            fill="#99a3ba"
                                        />
                                        <path
                                            id="Caminho_162"
                                            data-name="Caminho 162"
                                            d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,1.5A10.5,10.5,0,1,0,22.5,12,10.512,10.512,0,0,0,12,1.5Zm0,0"
                                            fill="#99a3ba"
                                        />
                                        <path
                                            id="Caminho_163"
                                            data-name="Caminho 163"
                                            d="M12,14.75a.75.75,0,0,1-.75-.75V12.99a2.255,2.255,0,0,1,1.5-2.122,3.1,3.1,0,0,0,2-2.618,2.75,2.75,0,0,0-5.5,0,.75.75,0,1,1-1.5,0,4.25,4.25,0,0,1,8.5,0,4.6,4.6,0,0,1-3,4.033.75.75,0,0,0-.5.708V14A.75.75,0,0,1,12,14.75Zm0,0"
                                            fill="#99a3ba"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="menu-mobile__footer-item-title">Ajuda</div>
                    </a>
                    <div class="menu-mobile__footer-item" @click="showLogin()">
                        <div class="menu-mobile__footer-item-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18.904"
                                height="22.047"
                                viewBox="0 0 18.904 22.047"
                            >
                                <path
                                    id="União_3"
                                    data-name="União 3"
                                    d="M-1989.214,22a.786.786,0,0,1-.786-.786V17.547a4.982,4.982,0,0,1,4.976-4.976h8.906a4.982,4.982,0,0,1,4.976,4.976v3.667a.786.786,0,0,1-.785.786Zm.786-4.453v2.881h15.714V17.547a3.409,3.409,0,0,0-3.4-3.4h-8.906A3.408,3.408,0,0,0-1988.428,17.547Zm2.618-12.309A5.244,5.244,0,0,1-1980.571,0a5.244,5.244,0,0,1,5.238,5.238,5.244,5.244,0,0,1-5.238,5.239A5.244,5.244,0,0,1-1985.81,5.238Zm1.572,0a3.67,3.67,0,0,0,3.667,3.667,3.67,3.67,0,0,0,3.666-3.667,3.67,3.67,0,0,0-3.666-3.666A3.67,3.67,0,0,0-1984.238,5.238Z"
                                    transform="translate(1990.024 0.023)"
                                    fill="#99a3ba"
                                    stroke="#707070"
                                    stroke-width="0.047"
                                />
                            </svg>
                        </div>
                        <div class="menu-mobile__footer-item-title">Entrar</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fontSize: 1,
            minSize: 3,
            lineHeight: 4,
            isVisible: false,
            categories: [],
            selectedCategory: null,
        };
    },
    mounted() {
        this.getCategoriesFromDB();
    },
    computed: {
        isMainMenu() {
            return !this.selectedCategory;
        },
        isSubMenu() {
            return !!this.selectedCategory;
        },
        themes() {
            if (!this.selectedCategory) {
                return [];
            }
            return this.selectedCategory.themes;
        },
        helpCenterUrl() {
            return route('help-center')
        }
    },
    methods: {
        showMenu() {
            this.isVisible = true;
        },
        hideMenu() {
            this.isVisible = false;
        },
        showMainMenu() {
            this.selectedCategory = null;
        },
        showSubMenu(category) {
            this.selectedCategory = category;
        },
        getCategoriesFromDB() {
            axios.get(route("home.categories")).then(({ data }) => {
                this.categories = data;
            });
        },
    },
};
</script>

<style></style>
