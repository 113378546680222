<template>
    <v-group ref="group" :config="{
            x: x,
            y: this.config.y,
            name: 'widget',
            draggable: editMode,
        }" @transformend="handleTransformEnd" @dragend="handleDragEnd">
        <v-text ref="element_0" :config="textConfig"/>
    </v-group>
</template>

<script>
import WidgetMixing from "../../mixins/WidgetMixin"

export default {
    name: "date-hour-01",
    mixins: [WidgetMixing],
    props: {
        config: Object,
        positions: Boolean,
        date: String,
        hour: String,
        editMode: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dayXPosition: 70,
            elements: 1
        }
    },
    computed: {
        datejs() {
            return dayjs(`${this.date} ${this.hour}`)
        },
        dayMonth() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('DD MMM');
        },
        weekday() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('dddd');
        },
        formattedHour() {
            if (!this.datejs.isValid()) {
                return null
            }
            return this.datejs.format('HH:mm');
        },
        text() {
            if (!this.isDateValid) {
                return ''
            }
            return `${this.dayMonth} | ${this.weekday} | ${this.formattedHour}`
        },
        textConfig() {
            return {
                ...this.config,
                x: 0,
                y: 0,
                text: this.text,
                name: 'text',
                fontFamily: this.config.elements[0].style.font.name,
                fontSize: this.forceNumber(this.config.elements[0].style.fontSize, 30),
                fontStyle: this.config.elements[0].style.fontStyle.weight,
                lineHeight: this.forceNumber(this.config.elements[0].style.lineHeight, 1),
                letterSpacing: this.forceNumber(this.config.elements[0].style.letterSpacing, 1),
                fill: this.config.elements[0].style.color,
                align: 'center',
                height: null,
                width: null
            }
        },
    },
    methods: {
        horizontalAlignStyle(option) {
            return {
                justifyContent: option
            };
        },
        renderStyle(font, fontStyle, style) {
            return {
                fontFamily: font.name,
                fontSize: `${style.fontSize}em`,
                lineHeight: (style.lineHeight === 'normal') ? 'normal' : `${style.lineHeight}em`,
                letterSpacing: `${style.letterSpacing}px`,
                textAlign: style.textAlign,
                color: style.color,
                borderColor: style.color,
                fontWeight: fontStyle.weight,
                fontStyle: (!!fontStyle.italic) ? 'italic' : 'normal',
                whiteSpace: 'nowrap'
            };
        },
    }
}
</script>
