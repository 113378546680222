<template>
    <v-group ref="layer" :config="{
            x: this.config.x,
            y: this.config.y,
        }">
        <v-text ref="text" :config="textConfig" @transformend="handleTransformEnd" @dragend="handleDragEnd"/>
        <v-transformer ref="transformer" />
    </v-group>
</template>

<script>

import {isNumber} from "lodash";

var FontFaceObserver = require('fontfaceobserver');


export default {
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ""
        },
        config: {
            type: Object,
            default: {}
        },
    },
    mounted() {
        // this.fixFontSize();
        // https://fontfaceobserver.com/

        var font = new FontFaceObserver(this.config.font.name, {
            weight: this.config.fontStyle.weight,
            style: 'italic'
        });
        font.load().then(() => {
            let text = this.$refs.text.getStage();
            text.fontFamily('')
            text.fontFamily(this.config.font.name);
            this.fixFontSize()
        });

    },
    computed: {
        textConfig() {
            return {
                ...this.config,
                x: 0,
                y: 0,
                height: this.editMode ? this.config.height : null,
                draggable: this.editMode,
                text: this.text,
                fontFamily: this.config.font.name,
                fontSize: this.forceNumber(this.config.style.fontSize, 30),
                align: this.config.style.textAlign,
                fontStyle: this.config.fontStyle.weight,
                lineHeight: this.forceNumber(this.config.style.lineHeight, 1),
                fill: this.config.style.color,
                letterSpacing: this.forceNumber(this.config.style.letterSpacing, 1),
                // strokeWidth: 3,
                // stroke: '#bb3bb9',
                // fillAfterStrokeEnabled: true,
            }
        }
    },
    methods: {
        forceNumber(number, fallback) {
            let floatNumber = parseFloat(number)
            if (isNumber(floatNumber)) {
                return floatNumber
            }
            return fallback;
        },
        fixFontSize() {

            if (!this.text) {
                return;
            }

            if (this.editMode) {
                return;
            }

            let maxBoxHeight = this.config.height;

            let boxOriginalTopPosition = this.config.y;

            let text = this.$refs.text.getStage();
            let parent = text.parent

            let maxFontSize = this.forceNumber(this.config.style.fontSize, 30)

            text.fontSize(maxFontSize)

            while (text.getHeight() > maxBoxHeight) {
                text.fontSize( text.fontSize() - 1);
            }

            if (this.config.transform_origin === 'Center') {
                let newTopPosition = (maxBoxHeight - text.getHeight()) / 2 + boxOriginalTopPosition;
                parent.y(newTopPosition)
            }
            if (this.config.transform_origin === 'South') {
                let newTopPosition = (maxBoxHeight - text.getHeight()) + boxOriginalTopPosition;
                parent.y(newTopPosition)
            }


        },
        handleTransformEnd(e) {
            this.$emit('transformend', e)
        },
        handleDragEnd(e) {
            this.$emit('dragend', e)
        }
    },
    watch: {
        text() {
            this.$nextTick(() => {
                this.fixFontSize();
            })
        }
    }
}
</script>
