const ForceDownloadFileMixin = {
    methods: {
        forceDownloadFile(url, outputFilename) {

            return new Promise(function(resolve, reject) {

                axios({
                    url,
                    method: 'GET',
                    responseType: 'blob', // important
                    crossdomain: true
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', outputFilename);
                    document.body.appendChild(link);
                    link.click();
                    resolve()
                }).catch((e) => {
                    reject(e);
                });

            });
        }
    }
}

export default ForceDownloadFileMixin;
