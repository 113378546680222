<template>
	<div class="wow select" v-click-outside="hideMenu" :class="{ 'select--visible': isListVisible }" :style="style">
		<div class="select__current" @click="toggleListVisibility" :class="selected.classes" :style="selected.customStyle">
			<span class="select__image" v-if="selected.image">
				<img :src="selected.image">
			</span>
			<span class="select__image" v-if="selected.icon">
				<i class="material-icons">{{ selected.icon }}</i>
			</span>
			<span class="select__image" v-if="selected.component">
				<component :is="selected.component.name" v-bind="selected.component.props"></component>
			</span>
			<span class="select__label">{{ selected.label }}</span>
			<i class="select__arrow material-icons" :class="{ 'select__arrow--up': isListVisible }">arrow_drop_down_circle</i>
		</div>

		<template v-show="false">
			<slot></slot>
		</template>

		<div class="select__list" :class="{'select__list--visible': isListVisible, 'select__list--image-grid': type === 'grid'}" :style="listStyle">
			<div class="select__option"
				 :class="[ {'select__options--active': item === selected }, item.classes]"
				 v-for="item in options"
				 @click="selectItem(item)"
				 v-if="item.value"
                 :style="item.customStyle"
			>
				<span class="select__image" v-if="item.image">
					<img :src="item.image">
				</span>
				<span class="select__image" v-if="item.icon">
					<i class="material-icons">{{ item.icon }}</i>
				</span>
				<span class="select__image" v-if="item.component">
					<component :is="item.component.name" v-bind="item.component.props"></component>
				</span>
				<span class="select__label">{{ item.$slots.default[0].text }}</span>
				<span class="select__action simple-link" v-if="item.actionlabel" @click.prevent="executeAction($event, item.action)">
					{{ item.actionlabel }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
			value: {
				type: [ String, Number, Object ],
				default: ''
			},
			width: {
			    type: [ String, Number ]
			},
			zIndex: {
			    type: [ String, Number ]
			},
			type: {
				type: [ String ],
				default: 'list'
			},
		},
		data(){
			return {
				selected: {},
				isListVisible: false,
				options: []
			}
		},
		created(){
            this.options = this.$children;
		},
		mounted(){
			if(this.find(this.value)) {
				this.selectItem(this.find(this.value));
			} else {
				this.selectItem(this.options[0]);
			}
		},
		computed: {
		    listStyle() {
		        let width = (this.width) ? this.width + "px" : "100% !important";

		        return {
		            width: width
				}
			},
            style() {
                if(this.zIndex){
                    return {
                        zIndex: this.zIndex
                    }
				}
				return {}
            }
		},
		methods: {
			find(value){
			    if(!value) {
			        return this.options[0];
				}
				let selected = null;
				this.options.forEach(function(element){
					if(element.value === value){
						selected = element;
					}
				});
				return (selected) ? selected : false;
			},
			selectItem(item){
				this.selected = item;
				this.selected.label = item.$slots.default[0].text;
				this.selected.label = item.$slots.default[0].text;
				this.isListVisible = false;
				this.$emit('input', item.value);
			},
			toggleListVisibility(){
				this.isListVisible = !this.isListVisible;
			},
			showList(){
				this.isListVisible = true;
			},
			hideMenu(){
				this.isListVisible = false;
			},
			executeAction(event, action){
				if(typeof action === 'function'){
					action();
				}
				event.stopPropagation();
			}
		},
		watch: {
		    selected(){
		        this.$emit('change',this.selected);
			},
			value(){
                this.selectItem(this.find(this.value));
			}
		},
		directives: {
	    'click-outside': {
	      bind: function(el, binding, vNode) {
	        // Provided expression must evaluate to a function.
	        if (typeof binding.value !== 'function') {
	        	const compName = vNode.context.name
	          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
	          if (compName) { warn += `Found in component '${compName}'` }

	          console.warn(warn)
	        }
	        // Define Handler and cache it on the element
	        const bubble = binding.modifiers.bubble
	        const handler = (e) => {
	          if (bubble || (!el.contains(e.target) && el !== e.target)) {
	          	binding.value(e)
	          }
	        }
	        el.__vueClickOutside__ = handler

	        // add Event Listeners
	        document.addEventListener('click', handler)
				},

	      unbind: function(el, binding) {
	        // Remove Event Listeners
	        document.removeEventListener('click', el.__vueClickOutside__)
	        el.__vueClickOutside__ = null
	      }
	    }
	  }
	}
</script>
<style scoped>

	.select{
		overflow: hidden;
		position: relative;
		z-index: 20;
	}

	.select--visible{
		overflow: visible;
	}

	.select__list{
		max-height: 210px;
		overflow-y: auto !important;
		/*display: none;*/
		transform: translateY(-20px);
		opacity: 0;
		transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease;
	}
	.select__list--visible{
		/*display: block;*/
		transform: translateY(0);
		opacity: 1;
	}
</style>
