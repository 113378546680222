<template>
    <div class="context-menu">
        <div class="context-menu__handler">
            <div class="order-card__options">
                <span class="material-icons">more_horiz</span>
            </div>
        </div>
        <div class="context-menu__body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "context-menu",
}
</script>

<style scoped>

</style>
