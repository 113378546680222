<template>
    <modal :name="modalName" :height="height" :width="width">
        <base-modal :name="modalName">

            <div class="register">

                <div class="register__body">

                    <div class="register__title">Criar conta</div>

                    <a :href="oAuthUrl" class="wow button secondary fluid large gmail-button">
                        <span>
                            <img src="/imgs/gmail-icon.svg" alt="">
                        </span>
                        <span>Entrar com Gmail</span>
                    </a>

                    <div class="or"></div>

                    <div class="wow warning fluid" v-if="error">
                        <span class="warning__icon material-icons">cancel</span>
                        <div class="warning__body">{{error_message}}</div>
                    </div>

                    <div class="wow form register__form">
                        <div class="row">
                            <label class="wow label">Nome</label>
                            <input class="wow input fluid large" type="text" v-model="user.name" />
                        </div>
                        <div class="row">
                            <label class="wow label">E-mail</label>
                            <input class="wow input fluid large" type="text" v-model="user.email" />
                        </div>
                        <div class="row">
                            <label class="wow label">Senha</label>
                            <input class="wow input fluid large" type="password" v-model="user.password" />
                        </div>
                    </div>

                    <wow-button class="large fluid submit" @click="register" :is-loading="isLoading">Cadastrar</wow-button>

                    <div class="register__legal-info">
                        Ao se cadastrar você aceita os <br />
                        <a href="#" class="legal-link">termos de uso</a> e <a href="#" class="legal-link">política de privacidade</a>
                    </div>

                </div>

                <div class="register__bottom">
                    <span>Já possui cadastro?</span>
                    <a href="#" class="simple-link" @click.prevent="showLogin">Clique aqui para fazer o login</a>
                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'Register',
        data() {
            return {
                modalName: 'register-modal',
                error: false,
                error_message: null,
                isLoading: false,
                user: {
                    name: null,
                    email: null,
                    password: null
                }
            }
        },
        mounted() {

        },
        computed: {
            oAuthUrl() {
                return route('oauth.google')
            },
            width() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '398px'
            },
            height() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return 'auto'
            }
        },
        methods: {
            validate() {

                if (!this.user.name || !this.user.email || !this.user.password) {
                    return {
                        isValid: false,
                        message: "Todos os campos são obrigatórios"
                    }
                }

                if (!email(this.user.email)) {
                    return {
                        isValid: false,
                        message: "Verifique o e-mail informado"
                    }
                }

                return {
                    isValid: true
                }
            },
            register() {

                let validation = this.validate()

                if (!validation.isValid) {
                    this.error = true;
                    this.error_message = validation.message;
                    return false;
                }

                this.isLoading = true;

                axios.post(
                    route('register'),
                    this.user
                ).then(() => {
                    window.location = route('auth-router')
                }).catch(({response}) => {

                    if (typeof response.data.email !== 'undefined' && response.data.email[0] === 'validation.unique') {
                        this.error = true;
                        this.error_message = "E-mail já cadastrado";
                        return false;
                    }

                    this.showUnexpectedErrorModal();

                }).finally(() => {
                    this.isLoading = false;
                });
            },

        }
    }
</script>
