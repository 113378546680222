<template>
    <div :style="renderValueStyle()" v-if="config.active">
        <div class="parent">
            <div class="text-container">
                <span class="text" :id="`${identify}-text`" :style="textStyles">
                  <slot></slot>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
// https://stackoverflow.com/questions/687998/auto-size-dynamic-text-to-fill-fixed-size-container
// https://dev.to/jankapunkt/make-text-fit-it-s-parent-size-using-javascript-m40
export default {
        props: {
            // fontSizeBase: [Number, String],
            text: String,
            multiLine: Boolean,
            reference: {
                type: String
            },
            debug: {
                type: Boolean,
                default: false
            },
            config: Object
        },
        name: "InvitationText",
        data() {
            return {
                bodyFontSize: 16,
                lineHeight: null,
                identify: null
            }
        },
        mounted() {
            this.getBodyFontSize();
            this.fontSizeBase = this.getFontSizeBase()
            this.lineHeight = this.config.style.lineHeight;
            this.identify = this.uuid()
            // this.$root.$on('change', (payload) => {
            //     if (this.reference === 'name') {
            //         this.fixFontSize();
            //         console.log(`Change ${this.reference}`)
            //     }
            // });
            this.$nextTick(() => {
                this.fixFontSize();
            });

        },
        computed: {
            contextWidth() {
                return this.$parent.currentWidth
            },
            baseWidth() {
                return this.$parent.baseWidth
            },
            textStyles() {
                return {
                    lineHeight: (this.lineHeight === 'normal') ? 'normal' : `${this.lineHeight}em`,
                    whiteSpace: (this.multiLine) ? 'pre-line' : 'normal'
                }
            },
            htmlText() {
                if (!this.text) {
                    return null;
                }
                return this.text;
                // return this.text.replace(/\n/g, "<br />")
            }
        },
        methods: {
            renderValueStyle() {
                return {
                    height: `${this.config.height_percent}%`,
                    // height: `20%`,
                    position: 'absolute',
                    fontFamily: this.config.font.name,
                    color: this.config.style.color,
                    top: `${this.config.top_percent}%`,
                    left: `${this.config.left_percent}%`,
                    width: `${this.config.width_percent}%`,
                    fontWeight: this.config.fontStyle.weight,
                    fontStyle: (!!this.config.style.italic) ? 'italic' : 'normal',
                    letterSpacing: `${this.config.style.letterSpacing}px`,
                    textAlign: this.config.style.textAlign,
                }
            },
            breaklines(string) {
                return string.replace(/\n/g, "<br />")
            },
            fixFontSize() {
                const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight

                const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {

                    (elements || [element]).forEach(el => {

                        let i = minSize
                        let overflow = false

                        const parent = el.parentNode

                        while (!overflow && i < maxSize) {
                            el.style.fontSize = `${i}${unit}`
                            overflow = isOverflown(parent)

                            if (!overflow) i += step
                        }

                        // revert to last state where no overflow happened
                        el.style.fontSize = `${i - step}${unit}`
                    })
                }

                this.$nextTick(() => {
                    resizeText({
                        elements: document.querySelectorAll(`#${this.identify}-text`),
                        step: 1,
                        unit: 'px',
                        maxSize: this.fontSizeBase
                    })
                });

            },
            getBodyFontSize() {
                let size = getComputedStyle(document.body).fontSize
                this.bodyFontSize = size.replace('px','');
            },
            getFontSizeBase() {
                return this.emToPx(this.config.style.fontSize);
            },
            emToPx(sizeInEm) {
                return this.bodyFontSize * sizeInEm;
            }
        },
    }
</script>

<style scoped>
    .parent {
        width: 100%;
        height: 100%;
        display: block;
    }
    .text-container {
        width: 100%;
        height: 100%;
    }
    .text {
        display: block;
        /*white-space: pre-line;*/
    }
</style>
