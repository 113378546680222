const KonvaAlignmentsMixin = {
    methods: {
        alignLeft(element) {
            element.x(0)
        },
        alignCenter(element) {
            element.x((element.parent.width() - element.textWidth) / 2)
        },
        alignRight(element) {
            element.x((element.parent.width() - element.textWidth))
        }
    }
}

export default KonvaAlignmentsMixin;
