<template>
    <div class="wow collapse" :class="{'show': isOpen}">
        <div class="collapse__title" @click="toggle()">
            {{ title }}
        </div>
        <span class="collapse__icon" @click="toggle()">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.41" viewBox="0 0 12 7.41">
              <path id="Caminho_159" data-name="Caminho 159" d="M7.41,8.59,12,13.17l4.59-4.58L18,10l-6,6L6,10Z" transform="translate(-6 -8.59)" fill="#99a3ba"/>
            </svg>
        </span>
        <div class="collapse__body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "wow-checkbox",
    props: {
        title: {
            type: String,
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.isOpen = this.open;
    },
    data () {
        return {
            isOpen: false
        }
    },
    methods: {
        toggle () {
            this.isOpen = !this.isOpen;
        }
    },
}
</script>

<style scoped lang="scss">

    .collapse {
        padding-left: 8px;
        transition: all .3s ease;
        position: relative;

        &__title {
            padding-right: 14px;
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
        }
        &__title:hover {
            color: #0097FF;
        }

        .collapse__icon {
            position: absolute;
            right: 14px;
            top: 17px;
            color: #99A3BA;
            cursor: pointer;
            transition: all .3s ease;
        }

        &__body {
            transition: all .3s ease;
            max-height: 0;
            overflow: hidden;
            width: 70%;
            font-size: 14px;
            line-height: 21px;

            p {
                padding-top: 12px;
            }
        }

    }



</style>
