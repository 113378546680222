<template>
    <div class="invitation" ref="invitation" :style="style">

        <img v-if="watermark && template.watermark_type === 'light'" class="invitation__watermark" src="/imgs/watermark-light.png" alt="">
        <img v-if="watermark && template.watermark_type === 'dark'" class="invitation__watermark" src="/imgs/watermark-dark.png" alt="">

        <invitation-text :config="template.values.main_text" :multi-line="true" ref="main-text">{{ invitation.main_text }}</invitation-text>

        <invitation-text :config="template.values.secondary_text" :multi-line="true" ref="secondary-text">{{ invitation.secondary_text }}</invitation-text>

        <invitation-text :config="template.values.name" ref="name">
            {{ invitation.name }}
        </invitation-text>

        <invitation-text :config="template.values.age" ref="age" reference="age">
            {{ modifyAge(invitation.age) }}
        </invitation-text>

        <invitation-text :config="template.values.gender">
            {{ invitation.gender }}
        </invitation-text>

        <invitation-text :config="template.values.party_date" ref="date-time">
            {{ invitation.party_date }}
        </invitation-text>

        <invitation-text :config="template.values.party_hour" ref="date-time">
            {{ invitation.party_hour }}
        </invitation-text>

        <invitation-text :config="template.values.party_date_hour"
            v-if="invitation.party_date || invitation.party_hour"
             ref="date-time"
        >
            {{ invitation.party_date }} às {{ invitation.party_hour }}
        </invitation-text>

        <invitation-text :config="template.values.venue_name" ref="venue">
            {{ invitation.venue_name }}
        </invitation-text>

        <invitation-text :config="template.values.address_complement" ref="address">
            {{ invitation.address_complement }}
        </invitation-text>

        <invitation-text :config="template.values.address_street_number"
            v-if="invitation.address_street || invitation.address_number"
             ref="address"
        >
            {{ invitation.address_street }}, {{ invitation.address_number }}
        </invitation-text>

        <invitation-text :config="template.values.address_district" ref="address">
            {{ invitation.address_district }}
        </invitation-text>

        <invitation-text :config="template.values.address_street_number_complement"
            v-if="invitation.address_street || invitation.address_number || invitation.address_complement"
            ref="address"
        >
            {{ invitation.address_street }}, {{ invitation.address_number }} - {{ invitation.address_complement }}
        </invitation-text>

        <invitation-text :config="template.values.address_street_number_complement_district"
            v-if="invitation.address_street || invitation.address_number || invitation.address_complement || invitation.address_district"
             ref="address"
        >
            {{ invitation.address_street }}, {{ invitation.address_number }} - {{ invitation.address_complement }} - {{ invitation.address_district }}
        </invitation-text>

        <invitation-text :config="template.values.address_city_state"
             v-if="invitation.address_city || invitation.address_state"
             ref="address"
        >
            {{ invitation.address_city }} / {{ invitation.address_state }}
        </invitation-text>

        <component :style="widgetStyle" :is="template.widget.name" :date="invitation.party_date" :hour="invitation.party_hour" :config="template.widget" :positions="true" v-if="template.widget.isActive"></component>

    </div>
</template>

<script>

export default {
    props: {
        template: {
            type: Object,
        },
        invitation: {
            type: Object
        },
        context: {
            type: String
        },
        watermark: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            currentWidth: null,
            baseWidth: 1200,
        }
    },
    mounted() {
        this.currentWidth = this.$refs.invitation.clientWidth;

        setTimeout(() => {
            this.$forceUpdate();
            this.fixAllTexts();
        },100)
        setTimeout(() => {
            this.$forceUpdate();
            this.fixAllTexts();
        },1000)
        setTimeout(() => {
            this.$forceUpdate();
            this.fixAllTexts();
        },2000);

    },
    computed: {
        style() {
            return {
                'background-image': `url(${this.template.background_image_path})`,
                // 'font-size': `${this.getFontSizeBase}px`
            }
        },
        getWidgetScale() {
            let invitationWidthDefault = 530;
            return (this.currentWidth / invitationWidthDefault);
        },
        widgetStyle() {
            if (this.context === 'render') {
                return null;
            }
            return {
                transform: `scale(${this.getWidgetScale})`
            }
        }
    },
    methods: {
        fixAllTexts() {
            let texts = Object.keys(this.$refs)
            texts.forEach((textKey) => {
                if (typeof(this.$refs[textKey].fixFontSize) === 'function') {
                    this.$refs[textKey].fixFontSize()
                }
            });
        },


        modifyAge(age) {
            if (!age) {
                return ''
            }

            if (!this.isOnlyNumbers(age.trim())) {
                return age;
            }

            let numericAge = parseInt(age)

            if (numericAge === 1) {
                return '1 ano';
            }
            return `${numericAge} anos`;
        },
        isOnlyNumbers(string) {
            return /^[0-9]+$/.test(string);
        },

    },
    watch: {
        invitation: {
            handler: function() {
                this.$root.$emit('change');
            },
            deep: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .invitation {
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        overflow: hidden;

        &__watermark {
            width: 100%;
            height: 100%;
            z-index: 10;
            user-select: none;
        }

    }
</style>
