<template>
    <modal :name="modalName" height="auto" :width="width" @before-open="beforeOpen">
        <base-modal :name="modalName">

            <div class="download-invitation">
                <div class="download-invitation__title">Download</div>

                <a :href="downloadJpgUrl" class="download-invitation__version">
                    <div class="download-invitation__version-icon">
                        <img src="/imgs/image-icon.svg">
                    </div>
                    <div class="download-invitation__version-info">
                        <div class="download-invitation__version-title">Baixar arquivo JPG</div>
                        <div class="download-invitation__version-description">Ideal para enviar no WhatsApp</div>
                    </div>
                </a>

                <a :href="downloadPdfUrl" class="download-invitation__version">
                    <div class="download-invitation__version-icon">
                        <img src="/imgs/pdf-icon.svg">
                    </div>
                    <div class="download-invitation__version-info">
                        <div class="download-invitation__version-title">Baixar arquivo PDF</div>
                        <div class="download-invitation__version-description">Pronto para imprimir</div>
                    </div>
                </a>

                <a @click.prevent="downloadEmail()" class="download-invitation__version">
                    <div class="download-invitation__version-icon">
                        <img src="/imgs/image-email-icon.svg">
                    </div>
                    <div class="download-invitation__version-info">
                        <div class="download-invitation__version-title">Receber no e-mail</div>
                        <div class="download-invitation__version-description">Receba os dois arquivos</div>
                    </div>
                </a>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'download-invitation',
        data() {
            return {
                modalName: 'download-invitation',
                invitation: {}
            }
        },
        computed: {
            downloadJpgUrl() {
                return route('download.jpg', this.invitation)
            },
            downloadPdfUrl() {
                return route('download.pdf', this.invitation)
            },
            width() {

                let screenWidth = screen.width

                if (this.isMobileSize) {
                    return screenWidth - 32;
                }
                return '382px'
            },
        },
        methods: {
            beforeOpen(event) {
                this.invitation = event.params.invitation;
            },
            downloadEmail() {
                axios.get(
                    route('download.email', this.invitation)
                ).then(() => {
                    alert('Você receberá os aquivos em alguns instantes. Tenha paciência.')
                });
            }
        }
    }
</script>
