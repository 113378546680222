<template>
    <a :href="cartUrl" class="cart nav-icons__item" :class="{'cart--badge': !isCartEmpty}">
        <img src="/imgs/cart.svg" alt="Carrinho">
    </a>
</template>

<script>
export default {
    name: "cart",
    data() {
        return {
            isCartEmpty: true
        }
    },
    mounted() {
        this.checkCartIsEmpty()
    },
    computed: {
        cartUrl() {
            return route('cart')
        },
    },
    methods: {
        checkCartIsEmpty() {
            axios.get(
                route('cart.check')
            ).then(({data}) => {
                this.isCartEmpty = data;
            });
        }
    }
}
</script>

<style scoped>

</style>
