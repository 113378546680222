<template>
    <modal :name="modalName" :height="height" :width="width">
        <base-modal :name="modalName">

            <div class="help-message">

                <div class="help-message__body">

                    <div class="help-message__title">Fale com a gente</div>

                    <div class="wow warning fluid" v-if="error">
                        <span class="warning__icon material-icons">cancel</span>
                        <div class="warning__body">E-email ou senha incorretos</div>
                    </div>

                    <div class="wow form help-message__form">
                        <div class="row">
                            <label class="wow label">Nome</label>
                            <input class="wow input fluid large" v-model="name" type="text" />
                        </div>
                        <div class="row">
                            <label class="wow label">E-mail</label>
                            <input class="wow input fluid large" v-model="email" type="text" />
                        </div>
                        <div class="row">
                            <label class="wow label">Mensagem</label>
                            <textarea class="wow textarea fluid large" v-model="message" />
                        </div>
                    </div>

                    <div class="help-message__buttons">
                        <wow-button class="fluid secondary" @click="cancel">Cancelar</wow-button>
                        <wow-button class="fluid icon" @click="send" :is-loading="isLoading">
                            <span>Entrar</span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Retângulo_411" data-name="Retângulo 411" width="20" height="20" transform="translate(1069 712)" fill="#fff" stroke="#707070" stroke-width="1"/>
                                    </clipPath>
                                </defs>
                                <g id="Grupo_de_máscara_7" data-name="Grupo de máscara 7" transform="translate(-1069 -712)" clip-path="url(#clip-path)">
                                    <g id="send-24px" transform="translate(1069 712)">
                                        <path id="Caminho_160" data-name="Caminho 160" d="M0,0H20V20H0Z" fill="none"/>
                                        <path id="Caminho_161" data-name="Caminho 161" d="M2.008,18,19.5,10.5,2.008,3,2,8.833,14.5,10.5,2,12.167Z" transform="translate(-0.333 -0.5)" fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </wow-button>
                    </div>

                </div>

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'help-message',
        data() {
            return {
                modalName: 'help-message',
                isLoading: false,
                email: null,
                name: null,
                message: null,
                error: false
            }
        },
        computed: {
            width() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return '398px'
            },
            height() {
                if (this.isMobileSize) {
                    return '100%'
                }
                return 'auto'
            }
        },
        methods: {
            cancel() {
                this.$modal.hide(this.modalName);
            },
            send() {

                if (!this.email || !this.message) {
                    this.error = true;
                    return false;
                }

                this.isLoading = true;

                axios.post(
                    route('help-center.send-message'),
                    {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    }
                ).then(() => {
                    this.$modal.hide(this.modalName);
                    this.$modal.show('help-message-confirmation')
                }).catch(() => {
                    this.showUnexpectedErrorModal();
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>
