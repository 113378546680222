<template>
    <input :type="type" :value="value" @input="onInput" @change="onChange" @keyup="onKeyUp" />
</template>


<script>
//

    export default {
        props: {
            value: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                default: 'date',
            },
        },
        methods: {
            onInput(event) {
                this.$emit('input', event.target.value);
            },
            onChange(event) {
                this.$emit('change', event.target.value);
            },
            onKeyUp(event) {
                this.$emit(event);
            }
        },
    }
</script>


<style scoped>
input[type="date"],
input[type="time"]{
    position: relative;
}
/* hide calendar icon */
input[type="date"]::-webkit-calendar-picker-indicator ,
input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
}
</style>
