<template>
    <modal :name="modalName" height="auto" :width="width">
        <base-modal :name="modalName">

            <div class="account-success-updated">
                <div class="account-success-updated__title">Tudo certo</div>

                <p>Seus dados foram salvos com sucesso.</p>

                <br />
                <br />

            </div>

        </base-modal>
    </modal>
</template>

<script>
    export default {
        name: 'account-success-updated',
        data() {
            return {
                modalName: 'account-success-updated',
            }
        },
        computed: {
            width() {
                let screenWidth = screen.width

                if (this.isMobileSize) {
                    return screenWidth - 32;
                }
                return '382px'
            },
        },
    }
</script>
